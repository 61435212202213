<template>
    <v-app :class="$is_mobile ? 'mobile' : ''">
        <!-- ---------------------------------- BACK -->
        <back-illustrator></back-illustrator>
        <!-- ---------------------------------- HEADER -->
        <common-header></common-header>
        <!-- ---------------------------------- MAIN ROUTER -->
        <v-main style="padding-bottom:200px">
            <v-container fill-height>
                <v-row>
                    <v-col>
                        <router-view />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <!-- ---------------------------------- FOOTER -->
        <common-footer></common-footer>
    </v-app>
</template>

<script>
import CommonHeader from './components/common-header.vue'
import BackIllustrator from '@/components/back-illustrator.vue'
import CommonFooter from '@/components/common-footer.vue'
/*
@description Main application entry point
*/
export default {
    name: 'App',
    components: { CommonHeader, BackIllustrator, CommonFooter },
    data: () => ({
        times: [],
        datetimes: []
    }),
    watch: {
        /*
        @desc Scroll top when route changes
        */
        '$route'() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    },
}
</script>


<style>
body,
.v-card__title {
    word-break: break-word !important;
}
</style>
