<template>
    <v-container>
        <!-- ------------------------------------------------------------ CONNECT -->
        <div v-if="!want_to_register">
            <h1 class='mb-5'>{{$t('connect')}}</h1>
            <v-text-field
                @keydown="connect_error=null"
                outlined
                :label="$t('email')"
                v-model="email"
            ></v-text-field>
            <v-text-field
                @keydown="connect_error=null"
                outlined
                :label="$t('password')"
                v-model="password"
                type="password"
                @keydown.enter="connect"
            ></v-text-field>
            <v-btn
                text
                color="primary"
                small
                @click="$router.push('/password_reset')"
                style='margin-top:-50px'
            >{{$t('connector.forgottenpassword')}}</v-btn>
            <v-alert
                v-if="connect_error"
                type='error'
            >{{connect_error}}</v-alert>
            <v-checkbox
                v-model="remember_me"
                :label="$t('connector.rememberme')"
            ></v-checkbox>
            <v-col class="text-right ma-0 pa-0 mt-5">
                <v-btn
                    large
                    right
                    color="primary"
                    text
                    @click="want_to_register=true"
                >
                  {{$t('connector.register')}}
                </v-btn>
                <v-btn
                    large
                    right
                    class='ml-3'
                    color="primary"
                    @click="connect"
                >
                  {{$t('connect')}}
                </v-btn>
            </v-col>
        </div>
        <!-- ------------------------------------------------------------ REGISTER -->
        <v-slide-x-transition>
          <div v-if="want_to_register && !success">
            <h1 class='mb-5'>{{$t('connector.register')}}</h1>
            <v-form v-model="register_form_is_valid">
              <v-text-field
                  v-for="(info,name) in user_form"
                  :key="name"
                  outlined
                  :label="name"
                  :rules="[
                            info.required !== false ? rules.required:null, 
                            info.link=='confirm_password' ? passwordConfirmationRule : null,
                            info.link=='password' ? rules.password : null,
                            info.link=='email' ? rules.email : null
                        ].filter(e=>e)
                    "
                  :type="info.disp ? '' : info.type"
                  :append-icon="info.type == 'password' ? info.disp ? 'mdi-eye' : 'mdi-eye-off' : ''"
                  @click:append="info.disp = !info.disp"
                  v-model="register_form[info.link]"
              ></v-text-field>
              <v-checkbox
                  v-model="accept_cgu"
                  class='conditions'
              >
                <template slot="label">
                  {{$t('cart.acceptcgu1')}}<router-link
                    to="/cgu#cgu"
                    @click.stop
                >{{$t('cart.acceptcgu2')}}</router-link>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="accept_conf"
                  class='conditions'
              >
                <template slot="label">
                  {{$t('cart.acceptconf1')}}<router-link
                    to="/cgu#confidentialite"
                    @click.stop
                >{{$t('cart.acceptconf2')}}</router-link>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="certificate_data"
                  class='conditions'
              >
                <template slot="label">
                  {{$t('cart.certificate')}}
                </template>
              </v-checkbox>
            </v-form>

            <v-alert
                v-if="connect_error"
                type='error'
            >{{connect_error}}</v-alert>

            <v-col class="text-right ma-0 pa-0 mt-5">
              <v-btn
                  large
                  right
                  color="primary"
                  text
                  @click="want_to_register=false"
              >
                {{$t('cancel')}}
              </v-btn>
              <v-btn
                  large
                  right
                  class='ml-3'
                  color="primary"
                  @click="register"
                  :disabled="!register_form_is_valid||!accept_cgu||!accept_conf||!certificate_data"
              >
                {{$t('validate')}}
              </v-btn>
            </v-col>
          </div>
        </v-slide-x-transition>
        <!-- ------------------------------------------------------------ REGISTER SUCCEED -->
        <v-alert
            type="success"
            v-if="success"
            class="mt-5"
        >
          {{$t('connector.successalert')}}
        </v-alert>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
/*
@desc User connection dialog Component
*/
export default {
    props: ['close_to'],
    data: () => ({

        email: '',
        password: '',
        remember_me: false,

        connect_error: null,

        want_to_register: false,

        register_form_is_valid: false,

        success: false,

        accept_cgu: false,
        accept_conf: false,
        certificate_data: false,
    }),
    computed: {
        ...mapGetters(['is_connected']),
        /*
        @desc Double Password confirmation computer
        */
        passwordConfirmationRule(v) {
            return () => (this.register_form.password === this.register_form.confirm_password) || this.$t('differentpasswords')
        },
        user_form() {
          return {
            [this.$t('email')]: {link: 'email'},
            [this.$t('password')]: {type: 'password', link: 'password', disp: false},
            [this.$t('confirmpassword')]: {type: 'password', link: 'confirm_password', disp: false},
            [this.$t('firstname')]: {link: 'firstName'},
            [this.$t('lastname')]: {link: 'lastName'},
            //[this.$t('phonenumber')]: {required: false, link: 'phone'}
          }
        },
        rules() {
          return{
            required: v => !!v || this.$t('requiredfield'),
            password: v => {
              const mins = !!v.match(/[a-z]+/)
              const maj = !!v.match(/[A-Z]+/)
              const nums = !!v.match(/[0-9]+/)
              const height = v.length >= 8
              return (maj && mins && nums && height) || this.$t('requiredpassword')
            },
            email: v => v.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g) || 'E-mail incorrect'
          }
        },
        register_form() {
          return {
          email: '',
          password: '',
          langKey: this.$i18n.locale,
          confirmPassword: '',
          lastName: '',
          firstName: '',
          phone: ''
          }
        },
    },
    methods: {
        ...mapActions(['reconnect']),
        /*
        @desc Connect user using comp data from connect form
        */
        async connect() {
            const conn_demand = { username: this.email, password: this.password }
            try {
                const { id_token } = await this.$api.auth.authenticate(conn_demand)
                const storage = this.remember_me ? localStorage : localStorage
                storage.setItem('account-token', id_token)
                if (await this.reconnect(this.$api.auth.get)) {
                    if (this.close_to) this.$router.push(this.close_to)
                    this.$emit('connected')
                } else {
                    this.connect_error = this.$t('connector.failedconnexion')
                }
            } catch (e) {
                this.connect_error = this.$t('connector.wrongusername')
            }
        },
        /*
        @desc Register user using comp data from register form
        */
        async register() {
            const { email, firstName, lastName, password, confirmPassword, langKey, phone } = this.register_form
            const account = { login: email, email, firstName, lastName, password, confirmPassword, langKey, phone }
            try {
                const registered = await this.$api.auth.register(account)
                this.success = true

            } catch (e) {
                console.error(e)
                this.false = true
                this.connect_error = this.$t('connector.registeringerror')
            }
        }
    },
    /*
    @desc Auto re-route if already connected
    */
    mounted() {
        setTimeout(() => {
            if (this.is_connected) {
                this.$router.push('/account')
            }
        }, 1000)
    }
}
</script>
